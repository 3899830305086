import imageUrlBuilder from '@sanity/image-url';
import axios from 'axios';
import * as act from './constants';
import { store } from './store';
import _ from 'lodash';
import { client, returnApiLogin, returnApiServer, returnZero } from '../utils';

export const handleClickSearch = (isOpen) => dispatch => {
    if (isOpen === true) {
        dispatch({ type: act.CLOSE_SEARCH_MODAL });
    } else if (isOpen === false) {
        dispatch({ type: act.OPEN_SEARCH_MODAL });
    }
}

export const setLoading = (bool) => dispatch => {
    dispatch({
        type: act.LOADING_FILTERED_SEARCH, payload: bool
    });
};

export const inputSearch = (value) => async dispatch => {
    setLoading(true);
    dispatch({
        type: act.INPUT_SEARCH, payload: value
    });

    const imgBuilder = imageUrlBuilder({
        projectId: 'nk5lirbt',
        dataset: 'production',
        useCdn: true
    });

    try {
        if (value.length !== 0) {
            const query = encodeURIComponent(`*[ _type == "post" && (title match "${value}*" ) ]{ title, "category": categories[]->title[0], _createdAt, slug, body, mainImage, "authorName":author->name, "authorImage": author->image } `);
            const url = `https://nk5lirbt.api.sanity.io/v1/data/query/production?query=${query}`;

            const result = await fetch(url).then(res => res.json());
            const res = await result.result;

            if (!res.error && res.length !== 0) {
                dispatch({
                    type: act.GET_FILTERED_SEARCH, payload: res.map(data => ({
                        ...data,
                        mainImage: imgBuilder.image(data.mainImage),
                        authorImage: imgBuilder.image(data.authorImage),

                    }))
                });
            } else if (res.length === 0) {
                dispatch({
                    type: act.GET_FILTERED_SEARCH, payload: { error: '0 found' }
                });
            }
        } else {
            dispatch({
                type: act.GET_FILTERED_SEARCH, payload: { error: '0 found' }
            });
        }
    } catch (err) {
        dispatch({
            type: act.GET_FILTERED_SEARCH, payload: { error: 'An error occured' }
        });
    }

}

export const selectCategory = (tag) => dispatch => {
    dispatch(selectPage(1));
    dispatch({
        type: act.SELECT_CATEGORY, payload: tag
    })
}

export const loadingCategory = (bool) => ({
    type: act.LOADING_CATEGORY, payload: bool
})


export const unselectCategory = () => ({
    type: act.UNSELECT_CATEGORY
})

export const countPageNumbers = () => async dispatch => {
    const query = encodeURIComponent(`count(*[ _type == "post" ])`);
    const url = `https://nk5lirbt.api.sanity.io/v1/data/query/production?query=${query}`;

    try {
        const result = await fetch(url).then(res => res.json());
        let count = await result.result/7;
        if (!Number.isInteger(count)) {
            count = (Math.trunc(count)+1);
        }
        
        dispatch({
            type: act.COUNT_PAGE_NUMBERS, payload: count
        })
    } catch (err) {   
    }
}

export const selectPage = (value) => async dispatch => {
    dispatch(unselectCategory());
    dispatch({
        type: act.GET_PAGES_LOADING
    });
    dispatch({
        type: act.SET_PAGE_NUMBER, payload: value
    });
    try {
        let itemsPerPage = 6;

        let start = value === 1?0:value+itemsPerPage-1;
        let end = value === 1?itemsPerPage:itemsPerPage*value;

        const query = encodeURIComponent(`*[ _type == "post" ][${start}..${end}] | order (_createdAt asc) { title, "category": categories[]->title[0], _createdAt, slug, body, mainImage, "authorName": author->name, "authorImage": author->image, likes }`);
        const url = (qry) => `https://nk5lirbt.api.sanity.io/v1/data/query/production?query=${qry}`;
        const result = await fetch(url(query)).then(res => res.json());
        dispatch({
            type: act.GET_PAGES, payload: result.result
        })
    } catch (err) {
        
        dispatch({
            type: act.GET_PAGES, payload: []
        })
    }
}

export const openSignInModal = () => ({
    type: act.OPEN_SIGN_IN_MODAL
})

export const closeSignInModal = () => ({
    type: act.CLOSE_SIGN_IN_MODAL
})

export const loginInputEmail = (value) => ({
    type: act.LOGIN_INPUT_EMAIL, payload: value
})

export const loginInputPassword = (value) => ({
    type: act.LOGIN_INPUT_PASSWORD, payload: value
})

export const requestUserData = () => async (dispatch) => {
    dispatch({ type: act.REQUEST_USER_DATA_LOADING });
    try {
        const { email, password } = store.getState().loginDetails;
        await axios({
            method: 'POST',
            url: `${returnApiServer}/auth/login`,
            data: {
                email: email,
                password: password
            },
            withCredentials: true
        }).then(res => {
            if (res.data.email) {
                dispatch({
                    type: act.REQUEST_USER_DATA_SUCCESS, payload: res.data
                })
            } else {
                dispatch({
                    type: act.REQUEST_USER_DATA_FAILED, payload: 'Incorrect email or password'
                })
            }
        });
    } catch (err) {
        dispatch({
            type: act.REQUEST_USER_DATA_FAILED, payload: 'An error occured. Please try again later.'
        })
    }
}

export const requestUserDataGoogle = (res) => async dispatch => {
    dispatch({ type: act.REQUEST_USER_DATA_LOADING });
    try {
        let data = await axios({
            method: 'POST',
            url: `${returnApiServer}/auth/google`,
            data: res.profileObj,
            withCredentials: true
        });
        let resp = await data.data;
        
        if (resp.email) {
            dispatch({ type: act.REQUEST_USER_DATA_SUCCESS, payload: resp });
        } else {
            dispatch({ type: act.REQUEST_USER_DATA_FAILED, payload: 'An error occured' });
        }
    } catch (err) {
        console.error(err);
        dispatch({ type: act.REQUEST_USER_DATA_FAILED, payload: 'Network is down. Please try again later.' });
    }
}

export const logoutUser = () => dispatch => {
    try {
        axios.get(`${returnApiServer}/auth/logout`, { withCredentials: true })
        .then(() => {
            dispatch({ type: act.USER_LOGOUT })
        }).catch(() => {
            // do nothing
        })
    } catch (err) {
        
    }
}

export const reloadUserData = () => async dispatch => {
    try {
        let response = await axios({
            method: "GET",
            withCredentials: true,
            url: `${returnApiServer}/auth/info`
        });

        if (!response.data.email) {
            dispatch({ type: act.LOGOUT_USER });
        }
    } catch (err) {
        dispatch({ type: act.LOGOUT_USER });
    }
}

export const inputComment = (value) => ({
    type: act.INPUT_COMMENT, payload: value
})

export const retrieveComments = (_id) => async dispatch => {
    try {
        let query = `*[ _type == "post" && _id == "${_id}"]{ "comments": 
        *[ _type == "comment" && post._ref == ^._id ]
            { 
                name, 
                text, 
                _createdAt 
            } 
        }`;
        let res = await client.fetch(query);
        if (res[0].comments.length) {
            dispatch({
                type: act.RETRIEVE_COMMENTS, payload: res[0].comments
            })
        } else {
            dispatch({
                type: act.RETRIEVE_COMMENTS, payload: []
            })
        }
    } catch (err) {
        console.error(err);
    }
}

export const submittingComment = (boolean) => ({
    type: act.SUBMIT_COMMENT_LOADING, payload: boolean
})

export const submitComment = (_id) => async dispatch => {
    dispatch(submittingComment(true));
    let firstName =  _.capitalize(store.getState().requestUserData.userData.firstName);
    let lastName = _.capitalize(store.getState().requestUserData.userData.lastName);

    
    
    let name = `${firstName} ${lastName}`;
    let comment = await store.getState().commentDetails.input;

    try {
        if (comment.length > 10) {
            let res = await client.create({
                _type: 'comment',
                name,
                text: comment,
                post: {
                    _type: 'reference',
                    _ref: _id
                }
            });
            if (res._createdAt) {
                dispatch({ type: act.SUBMIT_COMMENT_SUCCESS, payload: true });
                setTimeout(() => {
                    dispatch({ type: act.SUBMIT_COMMENT_SUCCESS, payload: false });
                }, 4000);
                dispatch(submittingComment(false));
            } else {
                dispatch({ type: act.SUBMIT_COMMENT_FAILED, error: true, success: false });
                setTimeout(() => {
                    dispatch({ type: act.SUBMIT_COMMENT_FAILED, error: false, success: false });
                }, 4000);
                dispatch(submittingComment(false));
            }
        } else {
            dispatch({ type: act.SUBMIT_COMMENT_FAILED, error: 'You must enter more than 10 words.', success: false });
            setTimeout(() => {
                dispatch({ type: act.SUBMIT_COMMENT_FAILED, error: false, success: false });
            }, 8000);
            dispatch(submittingComment(false));
        }
    } catch (err) {
        console.error(err);
    }
}

export const setActiveIndex = (num) => ({
    type: act.SET_ACTIVE_INDEX, payload: num
})

export const setActiveIndexLoading = (boolean) => ({
    type: act.SET_ACTIVE_INDEX_LOADING, payload: boolean
})

export const fetchReadIndexes = (elm) => dispatch => {
    if (elm) {
        dispatch(setActiveIndexLoading(true));
        const elmHeight = elm.offsetHeight;
        let wordsPerIndex = 550;

        let indexes = Math.trunc(elmHeight / wordsPerIndex);

        let array = _.times(indexes, String);
        let newArray = array.map(num => (parseInt(num) + 1))
        .map(num => (
            num.toString()
        )).map(string => (
            returnZero(string)
        ));
        
        let height = elm.offsetHeight;
        
        dispatch({
            type: act.RETRIEVE_READ_INDEX, payload: newArray.length > 1?newArray:[], height: height
        });
        dispatch(setActiveIndexLoading(false));
    }
}