export const OPEN_SEARCH_MODAL = 'OPEN_SEARCH_MODAL';
export const CLOSE_SEARCH_MODAL = 'CLOSE_SEARCH_MODAL';

export const OPEN_SIGN_IN_MODAL = 'OPEN_SIGN_IN_MODAL';
export const CLOSE_SIGN_IN_MODAL = 'CLOSE_SIGN_IN_MODAL';

export const SELECT_CATEGORY = 'SELECT_CATEGORY';
export const LOADING_CATEGORY = 'LOADING_CATEGORY';
export const UNSELECT_CATEGORY = 'UNSELECT_CATEGORY';

export const INPUT_SEARCH = 'INPUT_SEARCH';
export const LOADING_FILTERED_SEARCH = 'LOADING_FILTERED_SEARCH';
export const GET_FILTERED_SEARCH = 'GET_FILTERED_SEARCH';

export const COUNT_PAGE_NUMBERS = 'COUNT_PAGE_NUMBERS';
export const SET_PAGE_NUMBER = 'SET_PAGE_NUMBER';
export const GET_PAGES = 'GET_PAGES';
export const GET_PAGES_LOADING = 'GET_PAGES_LOADING';

export const LOGIN_INPUT_EMAIL = 'LOGIN_INPUT_EMAIL';
export const LOGIN_INPUT_PASSWORD = 'LOGIN_INPUT_PASSWORD';

export const REQUEST_USER_DATA_SUCCESS = 'REQUEST_USER_DATA_SUCCESS';
export const REQUEST_USER_DATA_FAILED = 'REQUEST_USER_DATA_FAILED';
export const REQUEST_USER_DATA_LOADING = 'REQUEST_USER_DATA_LOADING';
export const REQUEST_USER_DATA_RELOAD = 'REQUEST_USER_DATA_RELOAD';
export const LOGOUT_USER = 'LOGOUT_USER';

export const INPUT_COMMENT = 'INPUT_COMMENT';
export const RETRIEVE_COMMENTS = 'RETRIEVE_COMMENTS';
export const SUBMIT_COMMENT_FAILED = 'SUBMIT_COMMENT_FAILED';
export const SUBMIT_COMMENT_SUCCESS = 'SUBMIT_COMMENT_SUCCESS';
export const SUBMIT_COMMENT_LOADING = 'SUBMIT_COMMENT_LOADING';

export const INITIATE_LOADER = 'INITIATE_LOADER';
export const REMOVE_LOADER = 'REMOVE_LOADER';

export const SET_ACTIVE_INDEX = 'SET_ACTIVE_INDEX';
export const SET_ACTIVE_INDEX_LOADING = 'SET_ACTIVE_INDEX_LOADING';
export const RETRIEVE_READ_INDEX = 'RETRIEVE_READ_INDEX';
