import {
    IconButton,
    Box
} from '@mui/material';
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect } from 'react';


const ScrollToTop = ({ onClick }) => {
    useEffect(() => {
        AOS.init();
    }, [])
    return (
            <IconButton onClick={onClick} data-aos="fade-in" sx={{
                position: 'fixed',
                zIndex: 1,
                right: '32px',
                width: 48,
                height: 48,
                backgroundColor: 'white',
                border: '3px solid #01c9c5',
                boxShadow: '2px 2px 8px 0px rgba( 0, 0, 0, 0.2 )',
                bottom: '32px'
            }}>
                <KeyboardArrowDownRoundedIcon
                fontSize="large"
                sx={{ transform: 'rotate(180deg)', color: '#01c9c5' }}
                />
            </IconButton>
    )
}

export default ScrollToTop;