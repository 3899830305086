import {
    Box, Typography
} from '@mui/material';


const Tag = ({children, mt, fontSize, px, onClick, opacity, disableHover}) => {
    
    function addColor(tag){
        switch(tag){
            case 'Tips': return { background: '#ECE7FE', color: "#5E48AC" };
            case 'Grammar': return { color: '#9AABCE', background: '#E3EEFC' };
            case 'Fun': return { color: '#7EB1A0', background: '#D3FEF0' };
            case 'Edukasi': return { color: '#B3798F', background: '#FFE4F0' };
        }
    }

    return (
        <Box
        onClick={onClick}
        sx={{
            width: 'max-content',
            px: px?px:3,
            mt: mt,
            m: { xs: 0.5, sm: 0 },
            borderRadius: 2,
            cursor: disableHover?'default':'pointer',
            transition: 'all 0.4s',
            display: 'grid',
            placeItems: 'center',
            opacity: opacity,
            height: 28,
            '&:hover': {
                boxShadow: disableHover?'unset':'2px 2px 4px 2px rgba( 0, 0, 0, 0.2 )',
                opacity: disableHover?'100%':'80%'
            },
            ...addColor(children)
        }}>
            <Typography sx={{
                fontFamily: 'SkModernist',
                fontWeight: 700,
                fontSize: fontSize?fontSize:14
            }}>
                {children}
            </Typography>
        </Box>
    )
}

export default Tag;