import React, { useCallback, useEffect } from 'react';
import { connect } from 'react-redux';
import { reloadUserData } from '../../redux/actions';

const mapStateToProps = state => {
    return {
        isAuthenticated: state.requestUserData.isAuthenticated
    }
};

const mapDispatchToProps = dispatch => {
    return {
        onRefreshUserData: () => dispatch(reloadUserData())
    }
};

const StateWrapper = (props) => {
    const { isAuthenticated, children, onRefreshUserData } = props;

    const reloadUserData = useCallback(() => onRefreshUserData(), [onRefreshUserData]);

    useEffect(() => {
        if (isAuthenticated) {
            const timedUserDataCheck = setInterval(() => reloadUserData(), 3000);
            return () => {
                clearInterval(timedUserDataCheck);
            }
        }
    }, [reloadUserData, isAuthenticated]);

    return (
        children
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(StateWrapper);