import {
    InputBase, 
    Box,
    Button,
    Typography
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { useState } from 'react';
import { inputSearch } from '../../redux/actions';
import { connect } from 'react-redux';

const mapStateToProps = state => {
    return {
        value: state.searchModal.value
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onInput: (e) => dispatch(inputSearch(e.target.value))
    }
}

const Searchbox = ({ value, onInput }) => {
    const [ isFocused, setFocused ] = useState(false);
    return (
        <Box
        component="form"
        elevation={1}
        sx={{
            p: 1,
            display: 'flex',
            alignItems: 'center',
            alignSelf: 'center',
            backgroundColor: '#F3F5F7',
            width: '100%',
            transition: 'all 0.15s',
            cursor: 'pointer',
            border: isFocused?'4px solid lightblue':'unset'
        }}
        >
            <SearchIcon sx={{ opacity: '40%' }}/>
            <InputBase
            onFocus={() => setFocused(true)}
            value={value}
            onInput={onInput}
            placeholder="Search"
            inputProps={{ 'aria-label': 'Search' }} 
            sx={{
                ml: 1,
                flex: 1,
                fontFamily: 'SkModernist',
                fontWeight: 'bold'
            }}
            />
            <Button sx={{ 
                background: '#61A0DC',
                color: 'white',
                fontFamily: 'SkModernist',
                fontWeight: 'bold',
                borderRadius: 3,
                '&:hover': {
                    background: '#3470AA'
                }
            }}>
                Go
            </Button>
        </Box>
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(Searchbox);