import {
    Alert as Container,
    AlertTitle
} from '@mui/material';
import _ from 'lodash';

export const Alert = ({ type, caption, sx }) => (
    <Container sx={sx} severity={type}>
        <AlertTitle sx={{ fontFamily: 'Poppins, sans-serif', fontWeight: 500}}>{_.capitalize(type)}</AlertTitle>
        { caption }
    </Container>
)