import {
  Dialog,
  Button,
  Typography,
  FormControl,
  Input,
  CircularProgress,
} from "@mui/material";
import { connect } from "react-redux";
import {
  closeSignInModal,
  loginInputEmail,
  loginInputPassword,
  logoutUser,
  requestUserDataGoogle,
} from "../../redux/actions";
import GoogleLogin from "react-google-login";
import GoogleIcon from "@mui/icons-material/Google";
import { useEffect, useState } from "react";
import { requestUserData } from "../../redux/actions";
import { Alert } from "../Alerts";
import _ from "lodash";

const mapStateToProps = (state) => {
  return {
    isOpen: state.signInModal.isOpen,
    values: state.loginDetails,
    data: state.requestUserData,
    isLoading: state.requestUserData.isLoading,
    isAuthenticated: state.requestUserData.isAuthenticated,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onClose: () => dispatch(closeSignInModal()),
    login: {
      onInputEmail: (e) => dispatch(loginInputEmail(e.target.value)),
      onInputPassword: (e) => dispatch(loginInputPassword(e.target.value)),
      onSubmit: () => dispatch(requestUserData()),
      onLogout: () => dispatch(logoutUser()),
      onGoogleSignIn: (res) => {
        dispatch(requestUserDataGoogle(res));
      },
    },
  };
};

const styles = {
  root: {
    flexGrow: 1,
    "& .MuiDialog-paper": {
      borderRadius: 3.6,
      padding: 4,
    },
  },
  heading: {
    fontSize: "32px",
  },
  caption: {
    color: "#777E90",
    fontSize: 16,
    fontWeight: 300,
    fontFamily: "Poppins, sans-serif",
    mt: 2,
  },
  googleIcon: {
    color: "#ff0053",
  },
  googleBtn: {
    color: "dimgrey",
    border: "1px solid #e2e8f0",
    borderRadius: 10,
    my: 2.5,
    fontSize: 15,
    fontFamily: "Poppins, sans-serif",
    fontWeight: 500,
    py: 1.2,
  },
  text: {
    boxSizing: "border-box",
    mt: 1,
    "& input": {
      px: 1.7,
      border: "1px solid #e2e8f0",
      fontFamily: "Poppins, sans-serif",
      fontSize: 14,
      fontWeight: 500,
      borderRadius: 25,
      py: 1.2,
    },
  },
  helperText: {
    mt: 2.5,
    fontSize: 14,
  },
  linkText: {
    color: "#0065ff",
    fontSize: 14,
    cursor: "pointer",
    transition: "opacity 0.2s",
    "&:hover": {
      opacity: 0.5,
    },
  },
  alertStyle: {
    mt: 2,
    borderRadius: 2,
  },
  logoutBtn: {
    mt: 5,
    width: "100%",
    py: 1.4,
    borderRadius: 24,
    fontSize: 15,
    background: "#ff3a3a",
    "&:hover": {
      background: "#af0000",
    },
  },
};

const SignInModal = ({
  isOpen,
  onClose,
  login,
  values,
  data,
  isLoading,
  isAuthenticated,
}) => {
  const [isLoggingOut, setLoggingOut] = useState(false);

  useEffect(() => {
    if (!isAuthenticated && isLoggingOut) {
      setLoggingOut(false);
    }
  }, [isAuthenticated, isLoggingOut, setLoggingOut]);

  const [isRegister, setRegister] = useState(false);
  if (data.isAuthenticated) {
    return (
      <Dialog
        fullWidth
        open={isOpen}
        aria-labelledby="sign-in-dialog"
        onClose={onClose}
        maxWidth="xs"
        sx={styles.root}
      >
        <Typography variant="h6" sx={styles.heading}>
          Hello, {_.capitalize(data.userData.firstName)}
        </Typography>
        <Typography variant="caption" sx={styles.caption}>
          Linguo helps you to become fluent in many language.
        </Typography>
        <Button
          onClick={() => {
            setLoggingOut(true);
            login.onLogout();
          }}
          variant="contained"
          sx={styles.logoutBtn}
        >
          {isLoggingOut ? (
            <CircularProgress color="primary" size={30} />
          ) : (
            "Logout"
          )}
        </Button>
      </Dialog>
    );
  }
  if (isRegister) {
    return (
      <Dialog
        fullWidth
        open={isOpen}
        aria-labelledby="sign-in-dialog"
        onClose={onClose}
        maxWidth="xs"
        sx={styles.root}
      >
        <Typography variant="h6" sx={styles.heading}>
          Register
        </Typography>
        <Typography variant="caption" sx={styles.caption}>
          Linguo helps you to become fluent in many language.
        </Typography>
        <FormControl sx={{ mt: 3.5 }}>
          <Typography sx={{ opacity: "70%" }}>First Name</Typography>
          <Input placeholder="Joko" disableUnderline sx={styles.text} />
        </FormControl>
        <FormControl sx={{ mt: 1.5 }}>
          <Typography sx={{ opacity: "70%" }}>Last Name</Typography>
          <Input placeholder="Lelono" disableUnderline sx={styles.text} />
        </FormControl>
        <FormControl sx={{ mt: 1.5 }}>
          <Typography sx={{ opacity: "70%" }}>Email</Typography>
          <Input
            placeholder="joko.lelono@mail.com"
            disableUnderline
            sx={styles.text}
          />
        </FormControl>
        <FormControl sx={{ mt: 1.5 }}>
          <Typography sx={{ opacity: "70%" }}>Password</Typography>
          <Input placeholder="joko123" disableUnderline sx={styles.text} />
        </FormControl>
        <Button
          variant="contained"
          sx={{ mt: 5, width: "100%", py: 1.4, borderRadius: 24, fontSize: 15 }}
        >
          Sign In
        </Button>
        <Typography sx={styles.helperText}>
          Already have an account?{" "}
          <a>
            <Typography
              sx={styles.linkText}
              onClick={() => setRegister(false)}
              component="span"
            >
              Login
            </Typography>
          </a>
        </Typography>
      </Dialog>
    );
  }
  return (
    <Dialog
      fullWidth
      open={isOpen}
      aria-labelledby="sign-in-dialog"
      onClose={onClose}
      maxWidth="xs"
      sx={styles.root}
    >
      <Typography variant="h6" sx={styles.heading}>
        Login
      </Typography>
      <Typography variant="caption" sx={styles.caption}>
        Linguo helps you to become fluent in many language.
      </Typography>
      {data.error && (
        <Alert type="error" sx={styles.alertStyle} caption={data.error} />
      )}
      <GoogleLogin
        clientId="223146413773-991d2vu5m537sk1b4h171eorjif6pi5b.apps.googleusercontent.com"
        onSuccess={login.onGoogleSignIn}
        cookiePolicy="single_host_origin"
        prompt="select_account"
        render={(renderProps) => (
          <Button
            sx={styles.googleBtn}
            startIcon={<GoogleIcon sx={styles.googleIcon} />}
            onClick={renderProps.onClick}
            disabled={renderProps.disabled}
          >
            Sign in with{" "}
            <span
              style={{ ...styles.googleIcon, marginLeft: 5, fontWeight: 600 }}
            >
              {" "}
              Google
            </span>
          </Button>
        )}
      />
      <Typography textAlign="center" sx={{ opacity: "70%" }}>
        OR
      </Typography>
      <FormControl>
        <Typography sx={{ opacity: "70%" }}>Email</Typography>
        <Input
          placeholder="joko.lelono@mail.com"
          value={values.email}
          onInput={login.onInputEmail}
          type="email"
          disableUnderline
          sx={{ ...styles.text, color: data.error ? "red" : "unset" }}
        />
      </FormControl>
      <FormControl sx={{ mt: 1.5 }}>
        <Typography sx={{ opacity: "70%" }}>Password</Typography>
        <Input
          type="password"
          placeholder="joko123"
          onInput={login.onInputPassword}
          value={values.password}
          disableUnderline
          sx={{ ...styles.text, color: data.error ? "red" : "unset" }}
        />
      </FormControl>
      <Button
        disabled={isLoading}
        onClick={login.onSubmit}
        variant="contained"
        sx={{ mt: 5, width: "100%", py: 1.4, borderRadius: 24, fontSize: 15 }}
      >
        {isLoading ? <CircularProgress color="primary" size={30} /> : "Submit"}
      </Button>
      <Typography sx={styles.helperText}>
        Don't have an account?{" "}
        <a>
          <Typography
            sx={styles.linkText}
            onClick={() => setRegister(true)}
            component="span"
          >
            Register
          </Typography>
        </a>
      </Typography>
    </Dialog>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(SignInModal);
