import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { createLogger } from 'redux-logger';
import rootReducer from './rootReducer';
import { throttle } from 'lodash';


const logger = createLogger();

const saveStateToLocalStorage = state => {
    try {
        const serializedState = JSON.stringify(state);
        localStorage.setItem('linguo-blog', serializedState);
    } catch { }
}

const loadStateFromLocalStorage = () => {
    try {
        const serializedState = localStorage.getItem('linguo-blog');
        if (serializedState === null) {
            return undefined;
        }
        return JSON.parse(serializedState);
    } catch (err) {
        return undefined
    }
}

const persistedState = loadStateFromLocalStorage();

export const store = createStore(rootReducer, persistedState, applyMiddleware(thunk, logger));

store.subscribe(throttle(() => saveStateToLocalStorage(store.getState()), 1000));