import TimeAgo from "javascript-time-ago";
import en from "javascript-time-ago/locale/en.json";
import sanityClient from "@sanity/client";

TimeAgo.addDefaultLocale(en);

export function timeElapsed(rawDate) {
  const date = Date.now() - new Date(rawDate).getTime();
  const timeAgo = new TimeAgo("en-GB");

  return timeAgo.format(Date.now() - date);
}

export function getDate(dateTime) {
  let date = new Date(dateTime);

  return date.toLocaleDateString("en-GB");
}

export function readingTime(text) {
  if (text) {
    const wpm = 1200; // word per minute
    let words = 0;
    text.body.forEach((item) => {
      item.children?.forEach((child) => {
        words += child.text.length;
      });
    });
    return Math.trunc(words / wpm);
  }
}

const isProduction = process.env.NODE_ENV === "production";

export const returnApiDb = () => {
  return isProduction ? "https://db.linguo.id" : "http://localhost:3001";
};

export const returnApiLogin = () => {
  return isProduction ? "https://main.linguo.id" : "http://localhost:5000";
};

export const returnApiMain = () => {
  return isProduction ? "https://www.linguo.id" : "http://localhost:3000";
};
//http://103.146.203.244/
export const returnApiServer = isProduction
  ? "https://main.linguo.id"
  : "http://localhost:5000";

export const PROJECT_ID = process.env.NEXT_PUBLIC_SANITY_PROJECT_ID;
export const DATASET = process.env.NEXT_PUBLIC_SANITY_DATASET;
export const CLIENT_TOKEN = process.env.NEXT_PUBLIC_SANITY_API_TOKEN;

export const client = sanityClient({
  projectId: PROJECT_ID,
  dataset: DATASET,
  useCdn: true,
  apiVersion: "2023-01-20",
  token: CLIENT_TOKEN,
});

export const returnZero = (digit) => ("0" + digit).slice(-2);
