import * as act from './constants';

export const initialSearchModalState = {
    isOpen: false,
    value: '',
    isLoading: false,
    filteredItems: []
}

export const searchModal = (state=initialSearchModalState, action) => {
    switch(action.type){
        case act.OPEN_SEARCH_MODAL:
            
            return { ...state, isOpen: true };
        case act.CLOSE_SEARCH_MODAL:
            
            return { ...state, isOpen: false };
        case act.INPUT_SEARCH:
            return { ...state, value: action.payload };
        case act.GET_FILTERED_SEARCH:
            return { ...state, filteredItems: action.payload, isLoading: false };
        case act.LOADING_FILTERED_SEARCH:
            return { ...state, isLoading: action.payload };
        default:
            return state;
    }
}

const initialCategoryState = {
    selected: null,
    loading: false
}

export const selectedCategory = (state=initialCategoryState, action) => {
    switch(action.type){
        case act.SELECT_CATEGORY:
            return { ...state, selected: action.payload, loading: false };
        case act.LOADING_CATEGORY:
            return { ...state, loading: action.payload };
        case act.UNSELECT_CATEGORY:
            return { ...state, selected: null, loading: false };
        default:
            return state;
    }
}

const initialPageNumberingState = {
    value: 1,
    pageCount: null,
    pages: [],
    isLoading: true
}

export const pageNumbering = (state=initialPageNumberingState, action) => {
    switch(action.type){
        case act.COUNT_PAGE_NUMBERS:
            return { ...state, pageCount: action.payload };
        case act.SET_PAGE_NUMBER:
            return { ...state, value: action.payload };
        case act.GET_PAGES:
            return { ...state, pages: action.payload, isLoading: false };
        case act.GET_PAGES_LOADING:
            return { ...state, isLoading: true };
        default:
            return state;
    }
}

const initialSignInModalState = {
    isOpen: false
}

export const signInModal = (state=initialSignInModalState, action) => {
    switch(action.type) {
        case act.OPEN_SIGN_IN_MODAL:
            return { ...state, isOpen: true };
        case act.CLOSE_SIGN_IN_MODAL:
            return { ...state, isOpen: false };
        default:
            return state;
    }
}

const initialSignInFieldState = {
    email: '',
    password: ''
}

export const loginDetails = (state=initialSignInFieldState, action) => {
    switch(action.type) {
        case act.LOGIN_INPUT_EMAIL:
            return { ...state, email: action.payload };
        case act.LOGIN_INPUT_PASSWORD:
            return { ...state, password: action.payload };
        default:
            return state;
    }
}

const initialUserDataState = {
    isAuthenticated: false,
    userData: null,
    error: null,
    isLoading: false
}

export const requestUserData = (state=initialUserDataState, action) => {
    switch(action.type) {
        case act.REQUEST_USER_DATA_SUCCESS:
            return { ...state, isAuthenticated: true, userData: action.payload, error: null, isLoading: false };
        case act.REQUEST_USER_DATA_FAILED:
            return { ...state, isAuthenticated: false, userData: null, error: action.payload, isLoading: false };
        case act.REQUEST_USER_DATA_LOADING:
            return { ...state, isLoading: true };
        case act.REQUEST_USER_DATA_RELOAD:
            return { ...state, userData: action.payload, isLoading: false, error: false };
        case act.LOGOUT_USER:
            return { ...state, isAuthenticated: false, userData: null, error: null, isLoading: false };
        default:
            return state;
    }
}

const initialCommentFieldState = {
    data: [],
    input: '',
    success: false,
    error: false,
    isLoading: false
}

export const commentDetails = (state=initialCommentFieldState, action) => {
    switch(action.type) {
        case act.INPUT_COMMENT:
            return { ...state, input: action.payload };
        case act.RETRIEVE_COMMENTS:
            return { ...state, data: action.payload, error: false };
        case act.SUBMIT_COMMENT_SUCCESS:
            return { ...state, success: action.payload, input: '', error: false };
        case act.SUBMIT_COMMENT_LOADING:
            return { ...state, isLoading: action.payload };
        case act.SUBMIT_COMMENT_FAILED:
            return { ...state, error: action.error, success: action.success, input: '' };
        default:
            return state;
    }
}

const initialPageDetailsState = {
    activeIndex: 0,
    blockHeight: 0,
    isLoading: false,
    indexes: [],
}

export const pageDetails = (state=initialPageDetailsState, action) => {
    switch(action.type) {
        case act.RETRIEVE_READ_INDEX:
            return { ...state, indexes: action.payload, blockHeight: action.height };
        case act.SET_ACTIVE_INDEX:
            return { ...state, activeIndex: action.payload };
        case act.SET_ACTIVE_INDEX_LOADING:
            return { ...state, isLoading: action.payload };
        default:
            return state;
    }
}

