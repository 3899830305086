import { combineReducers } from 'redux';
import { 
    searchModal,
    selectedCategory,
    pageNumbering,
    signInModal,
    loginDetails,
    requestUserData,
    commentDetails,
    pageDetails
} from './reducers';

const rootReducer = combineReducers({
    searchModal,
    selectedCategory,
    pageNumbering,
    signInModal,
    loginDetails,
    requestUserData,
    commentDetails,
    pageDetails
});

export default rootReducer;