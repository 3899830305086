import "../styles/globals.css";
import "../styles/animations.css";
import { createTheme, ThemeProvider } from "@mui/material";
import { useEffect, useState } from "react";
import { store } from "../redux/store";
import SearchModal from "../components/Modals/SearchModal";
import SignInModal from "../components/Modals/SignInModal";
import { Provider, useDispatch } from "react-redux";
import { reloadUserData } from "../redux/actions";
import { createWrapper } from "next-redux-wrapper";
import ScrollToTop from "../components/Scroller/ScrollToTop";
import dynamic from "next/dynamic";
import "nprogress/nprogress.css?v=2130";
import StateWrapper from "../components/Wrapper/StateWrapper";

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 400,
      md: 700,
      lg: 1200,
      xl: 1536
    }
  },
  palette: {
    type: "light",
    primary: {
      main: "#009A9D",
      light: "#00C9C5"
    },
    secondary: {
      main: "#F8CB2E"
    },
    text: {
      primary: "#23262f",
      secondary: "#B3B3B5"
    }
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          fontFamily: "Poppins",
          fontWeight: 600,
          textTransform: "none"
        }
      }
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          fontFamily: "SkModernist",
          fontWeight: "bold",
          letterSpacing: "-0.01em",
          lineHeight: "120%"
        }
      }
    }
  }
});

// Router.events.on('routeChangeStart', (url) => {
//
//   NProgress.start()
// })
// Router.events.on('routeChangeComplete', () => NProgress.done())
// Router.events.on('routeChangeError', () => NProgress.done())

const TopProgressBar = dynamic(
  () => {
    return import("../components/Loaders/TopProgressBar");
  },
  { ssr: false }
);

function MyApp({ Component, pageProps }) {
  const dispatch = useDispatch();
  const [scrollPosition, setScrollPosition] = useState(0);

  // useEffect(() => {
  //   const userDataReload = setInterval(() => dispatch(reloadUserData()), 3000);

  //   return () => clearInterval(userDataReload);
  // }, []);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  };

  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <StateWrapper>
          <TopProgressBar />
          <SearchModal />
          <SignInModal />
          <Component {...pageProps} />
          {scrollPosition > 300 && <ScrollToTop onClick={scrollToTop} />}
        </StateWrapper>
      </ThemeProvider>
    </Provider>
  );
}

const makeStore = () => store;
const wrapper = createWrapper(makeStore);

export default wrapper.withRedux(MyApp);
