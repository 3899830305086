import {
    Dialog,
    Box,
    Typography,
    CircularProgress
} from '@mui/material';
import { connect } from 'react-redux';
import { handleClickSearch } from '../../redux/actions';
import Searchbox from '../Search/Searchbox';
import Tag from '../Tags/Tag';
import { useRouter } from 'next/router';
import Highlighter from "react-highlight-words";

const mapStateToProps = state => {
    return {
        isOpen: state.searchModal.isOpen,
        value: state.searchModal.value,
        filteredItems: state.searchModal.filteredItems,
        isLoading: state.searchModal.isLoading
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onClose: (state) => dispatch(handleClickSearch(state))
    }
}

const SearchDialog = ({ isOpen, onClose, filteredItems, value, isLoading }) => {
    const router = useRouter();
    function handleClick(item){
        onClose(isOpen);
        router.push(`/post/${item}`);
    }

    return (
        <Dialog
        open={isOpen}
        aria-labelledby="search-dialog"
        onClose={() => onClose(isOpen)}
        sx={{
            width: "100%",
            '& .MuiPaper-root': {
                width: '70%',
                maxWidth: 600
            }
        }}
        >
            <Searchbox/>
            { value?(
                !isLoading?
                (filteredItems.length > 0 && !filteredItems.error)?
            (
                filteredItems.map(item => {
                    return (
                    <Box
                    key={item.title}
                    onClick={() => handleClick(item.slug.current)}
                    sx={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'row-reverse',
                        justifyContent: 'space-between',
                        borderBottom: '1px solid #f1f1f1',
                        borderTop: '1px solid #f1f1f1',
                        alignItems: 'center',
                        cursor: 'pointer',
                        px: 2,
                        position: 'relative',
                        py: 2,
                        transition: 'border 0.2s',
                        '& #item-title:after': {
                            opacity: 0,
                            transition: 'opacity 0.25s'
                        },
                        '&:hover': {
                            border: '3px solid #F8CB2E',
                            '& #img-container': {
                                transition: 'all 0.2s',
                                opacity: '40%'
                            },
                            '& #item-title:after': {
                                content: '"➤"',
                                color: '#F8CB2E',
                                position: 'absolute',
                                opacity: 1,
                                top: '58px',
                                left: '10px'
                            },
                            '& #item-title': {
                                paddingRight: '24px',
                                paddingLeft: '15px',
                                transition: 'all 0.5s'
                            }
                        }
                    }}>
                        <Box id="img-container" sx={{
                            position: 'relative',
                            width: 120,
                            height: 65,
                        }}>
                            <img
                            style={{ position: 'absolute', width: '100%', height: '100%', objectFit: 'cover', borderRadius: 24 }}
                            src={item.mainImage}
                            />
                        </Box>
                        <Box display="flex" flexDirection="column" justifyContent="space-between">
                            <Tag disableHover>
                                {item.category}
                            </Tag>
                            <Highlighter
                            className="highlighted-text"
                            highlightClassName="highlighted-text active"
                            searchWords={[value]}
                            textToHighlight={item.title}
                            />
                        </Box>
                    </Box>)
                })
            ) : (
                (<Typography sx={{
                    display: 'grid',
                    placeItems: 'center',
                    height: filteredItems.error?80:0,
                    color: '#009bcd'
                }}>
                    {filteredItems.error}
                </Typography>)
            ) : 
            // loading
            (
                <Box sx={{
                    display: 'grid',
                    placeItems: 'center',
                    height: 50
                }}>
                    <CircularProgress/>
                </Box>
            )
            ) : (
                null
            )
            }
        </Dialog>
    )
}



export default connect(mapStateToProps, mapDispatchToProps)(SearchDialog);